import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Card,
  Descriptions,
  Typography,
  Spin,
  Switch,
  message,
  Input,
  Tag,
  Modal,
  Form,
  Button,
  DatePicker,
  Select,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import ReactToPrint from "react-to-print";
import { useRef } from "react";

const { Title } = Typography;
const { Option } = Select;

const SingleLoan = () => {
  const { id } = useParams();
  const [loan, setLoan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [approved, setApproved] = useState(false);
  const [editingLatePaymentFee, setEditingLatePaymentFee] = useState(false);
  const [latePaymentFee, setLatePaymentFee] = useState("");
  const [remarks, setRemarks] = useState("");
  const [editingRemarks, setEditingRemarks] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loanBalance, setLoanBalance] = useState("");
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [loanAmount, setLoanAmount] = useState("");
  const [editingLoanAmount, setEditingLoanAmount] = useState(false);
  const [paymentForm] = useForm();
  const [form] = useForm();
  const componentRef = useRef();

  useEffect(() => {
    const fetchLoan = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/loans/${id}`
        );
        const fetchedLoan = response.data;
        setLoan(fetchedLoan);
        setApproved(fetchedLoan.approved);
        setLatePaymentFee(fetchedLoan.late_payment_fee);
        setRemarks(fetchedLoan.remarks);
        setLoanAmount(fetchedLoan.loan_amount);
        setLoanBalance(fetchedLoan.balance);
        setLoading(false);
        updateLoanStatus(fetchedLoan);
      } catch (error) {
        console.error("Error fetching loan:", error);
        setLoading(false);
      }
    };

    fetchLoan();
  }, [id]);

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  // Handler to save updated loan amount
  const handleLoanAmountBlur = async () => {
    const newLoanAmount = parseFloat(loanAmount);
    if (isNaN(newLoanAmount)) {
      message.error("Please enter a valid loan amount.");
      return;
    }

    const newBalance = newLoanAmount * 1.15; // Balance with 15% interest
    const newInstallmentAmount = newBalance / 3;

    try {
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/loans/${id}`, {
        loan_amount: newLoanAmount,
        balance: newBalance,
        installment_amount: newInstallmentAmount,
      });

      // Update local state
      setLoan((prevLoan) => ({
        ...prevLoan,
        loan_amount: newLoanAmount,
        balance: newBalance,
        installment_amount: newInstallmentAmount,
      }));
      setLoanBalance(newBalance);
      message.success("Loan Amount updated successfully");
      setEditingLoanAmount(false);
    } catch (error) {
      message.error("Failed to update Loan Amount. Please try again.");
      console.error("Error updating Loan Amount:", error);
    }
  };

  const currencyFormatter = (value) => `K${parseFloat(value).toFixed(2)}`;

  const handleToggleApproval = async (checked) => {
    try {
      const status = getStatus(checked, loan.balance, loan.due_date);
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/loans/${id}`, {
        approved: checked,
        status,
      });
      setApproved(checked);
      setLoan((prevLoan) => ({ ...prevLoan, status }));
      message.success(`Loan has been ${checked ? "approved" : "disapproved"}`);
    } catch (error) {
      message.error("Failed to update approval status. Please try again.");
      console.error("Error updating approval status:", error);
    }
  };

  const getStatus = (approved, balance, due_date) => {
    if (!approved) return "Pending";
    if (balance === 0) return "Closed";
    if (new Date() > new Date(due_date)) return "Overdue";
    return "Active";
  };

  const updateLoanStatus = async (loan) => {
    const status = getStatus(loan.approved, loan.balance, loan.due_date);
    if (loan.status !== status) {
      try {
        await axios.put(`${process.env.REACT_APP_SERVER_URL}/loans/${id}`, {
          status,
        });
        setLoan((prevLoan) => ({ ...prevLoan, status }));
      } catch (error) {
        console.error("Error updating loan status:", error);
      }
    }
  };

  const handleApplyInterest = async (values) => {
    const interestAmount = parseFloat(values.interest);
    const newBalance = loan.balance + interestAmount;

    try {
      // Update loan balance and add transaction
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/transactions/${id}/apply-interest`,
        {
          interestAmount: interestAmount,
        }
      );

      // Update local state
      setLoan((prevLoan) => ({ ...prevLoan, balance: newBalance }));
      setLoanBalance(newBalance);
      message.success("Interest applied successfully");
      setIsModalVisible(false);
    } catch (error) {
      message.error("Failed to apply interest. Please try again.");
      console.error("Error applying interest:", error);
    }
  };

  const handleLatePaymentFeeChange = (e) => {
    setLatePaymentFee(e.target.value);
  };

  const handleLatePaymentFeeBlur = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/loans/${id}`, {
        late_payment_fee: latePaymentFee,
      });
      message.success("Late Payment Fee updated successfully");
      setEditingLatePaymentFee(false);
    } catch (error) {
      message.error("Failed to update Late Payment Fee. Please try again.");
      console.error("Error updating Late Payment Fee:", error);
    }
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  const handleRemarksBlur = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/loans/${id}`, {
        remarks,
      });
      message.success("Remarks updated successfully");
      setEditingRemarks(false);
    } catch (error) {
      message.error("Failed to update remarks. Please try again.");
      console.error("Error updating remarks:", error);
    }
  };

  const getStatusTag = (status) => {
    switch (status) {
      case "Pending":
        return <Tag color="grey">Pending</Tag>;
      case "Active":
        return <Tag color="#36A2EB">Active</Tag>;
      case "Closed":
        return <Tag color="#FFCE56">Closed</Tag>;
      case "Overdue":
        return <Tag color="#FF6384">Overdue</Tag>;
      default:
        return <Tag>{status}</Tag>;
    }
  };

  const handleRecordPayment = async (values) => {
    const paymentAmount = parseFloat(values.payment);
    const newBalance = loan.balance - paymentAmount;

    try {
      // Update loan balance and add transaction
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/transactions`, {
        loan_id: id,
        transaction_date: values.transaction_date.format("YYYY-MM-DD"),
        transaction_type: "payment",
        amount: paymentAmount,
        payment_method: values.payment_method,
        remarks: values.remarks,
      });

      // Update local state
      setLoan((prevLoan) => ({ ...prevLoan, balance: newBalance }));
      setLoanBalance(newBalance);
      message.success("Payment recorded successfully");
      setIsPaymentModalVisible(false);
    } catch (error) {
      message.error("Failed to record payment. Please try again.");
      console.error("Error recording payment:", error);
    }
  };

  const can_approve = localStorage.getItem("can_approve");

  return (
    <div style={{ padding: "20px" }}>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spin />
          <p>
            <b style={{ fontWeight: "500" }}>Loading Loan Details...</b>
          </p>
        </div>
      ) : (
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={2}>Loan Details</Title>
            <div>
              {can_approve === "true" && (
                <Switch
                  checked={approved}
                  onChange={handleToggleApproval}
                  checkedChildren="Approved"
                  unCheckedChildren="Not Approved"
                />
              )}
              <Button
                type="default"
                danger
                onClick={() => setIsModalVisible(true)}
                style={{ marginLeft: "10px" }}
              >
                Apply Interest
              </Button>
              <Button
                type="primary"
                onClick={() => setIsPaymentModalVisible(true)}
                style={{ marginLeft: "10px" }}
                ghost
              >
                Record Payment
              </Button>
              <ReactToPrint
                trigger={() => (
                  <Button type="default" style={{ marginLeft: "10px" }}>
                    Print Statement
                  </Button>
                )}
                content={() => componentRef.current}
              />
              <Modal
                title="Apply Interest"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => form.submit()}
              >
                <Form form={form} onFinish={handleApplyInterest}>
                  <Form.Item
                    name="interest"
                    label="Interest Amount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the interest amount",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Form>
              </Modal>
              <Modal
                title="Record Payment"
                visible={isPaymentModalVisible}
                onCancel={() => setIsPaymentModalVisible(false)}
                onOk={() => paymentForm.submit()}
              >
                <Form form={paymentForm} onFinish={handleRecordPayment}>
                  <Form.Item
                    name="payment"
                    label="Payment Amount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the payment amount",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    name="transaction_date"
                    label="Transaction Date"
                    rules={[
                      {
                        required: true,
                        message: "Please select the transaction date",
                      },
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                  <Form.Item
                    name="payment_method"
                    label="Payment Method"
                    rules={[
                      {
                        required: true,
                        message: "Please select the payment method",
                      },
                    ]}
                  >
                    <Select placeholder="Select a payment method">
                      <Option value="Cash">Cash</Option>
                      <Option value="Bank Transfer">Bank Transfer</Option>
                      <Option value="Mobile Payment">Mobile Payment</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="remarks" label="Remarks">
                    <Input.TextArea />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </div>
          <div ref={componentRef}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Debtor Name">
                <span style={{ fontWeight: "bold", color: "black" }}>
                  {loan.Debtor.first_name} {loan.Debtor.last_name}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Loan Amount">
                {editingLoanAmount ? (
                  <Input
                    value={loanAmount}
                    onChange={handleLoanAmountChange}
                    onBlur={() => {
                      console.log("Loan amount blur triggered"); // Debug log
                      handleLoanAmountBlur();
                    }}
                    autoFocus
                  />
                ) : (
                  <div
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      color: "#009900",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      console.log("Editing Loan Amount clicked"); // Debug log
                      setEditingLoanAmount(true);
                    }}
                  >
                    {currencyFormatter(loanAmount)}
                    <EditOutlined
                      style={{ marginLeft: "8px", fontSize: "16px" }}
                    />
                  </div>
                )}
              </Descriptions.Item>

              <Descriptions.Item label="Balance">
                <span style={{ fontWeight: "bold", color: "red" }}>
                  {currencyFormatter(loanBalance)}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Installment Amount">
                {currencyFormatter(loan.installment_amount)}
              </Descriptions.Item>
              <Descriptions.Item label="Admin Fee">
                {currencyFormatter(loan.admin_fee)}
              </Descriptions.Item>
              <Descriptions.Item label="Total Amount Payable">
                {currencyFormatter(loan.total_amount_payable)}
              </Descriptions.Item>
              <Descriptions.Item label="Late Payment Fee">
                {editingLatePaymentFee ? (
                  <Input
                    value={latePaymentFee}
                    onChange={handleLatePaymentFeeChange}
                    onBlur={handleLatePaymentFeeBlur}
                    autoFocus
                  />
                ) : (
                  <span
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                    onClick={() => setEditingLatePaymentFee(true)}
                  >
                    {currencyFormatter(latePaymentFee)}
                    <EditOutlined style={{ marginLeft: "8px" }} />
                  </span>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Due Date">
                {new Date(loan.due_date).toLocaleDateString()}
              </Descriptions.Item>
              <Descriptions.Item label="Collateral Type">
                {loan.collateral_type}
              </Descriptions.Item>
              <Descriptions.Item label="Applied On">
                {new Date(loan.applied_on).toLocaleDateString()}
              </Descriptions.Item>
              <Descriptions.Item label="Interest Rate">
                {loan.interest_rate}%
              </Descriptions.Item>
              <Descriptions.Item label="Guarantor Name">
                {loan.guarantor_name}
              </Descriptions.Item>
              <Descriptions.Item label="Guarantor Phone">
                {loan.guarantor_phone}
              </Descriptions.Item>
              <Descriptions.Item label="Guarantor Address">
                {loan.guarantor_address}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                {getStatusTag(loan.status)}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Approved">
              {loan.approved ? "Yes" : "No"}
            </Descriptions.Item> */}
              <Descriptions.Item label="Added By">
                {loan.added_by}
              </Descriptions.Item>
              <Descriptions.Item label="Payslip">
                <a
                  href={loan.collateral_document}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Document
                </a>
              </Descriptions.Item>

              <Descriptions.Item label="Loan Type">
                {loan.loan_type}
              </Descriptions.Item>
              <Descriptions.Item label="Loan Term">
                {loan.loan_term} months
              </Descriptions.Item>
              <Descriptions.Item label="Payment Frequency">
                {loan.payment_frequency}
              </Descriptions.Item>

              {/* <Descriptions.Item label="Repayment Start Date">
                {new Date(loan.repayment_start_date).toLocaleDateString()}
              </Descriptions.Item>
              <Descriptions.Item label="Repayment End Date">
                {new Date(loan.repayment_end_date).toLocaleDateString()}
              </Descriptions.Item> */}

              <Descriptions.Item label="Remarks">
                {editingRemarks ? (
                  <Input
                    value={remarks}
                    onChange={handleRemarksChange}
                    onBlur={handleRemarksBlur}
                    autoFocus
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setEditingRemarks(true)}
                  >
                    <span>{remarks}</span>
                    <EditOutlined style={{ marginLeft: "8px" }} />
                  </div>
                )}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Card>
      )}
    </div>
  );
};

export default SingleLoan;
