import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Spin, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";

const Detbors = () => {
  const [debtors, setDebtors] = useState([]);
  const [filteredDebtors, setFilteredDebtors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDebtors = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/debtors`
        );
        setDebtors(response.data);
        setFilteredDebtors(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching debtors:", error);
        setLoading(false);
      }
    };

    fetchDebtors();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = debtors.filter((debtor) =>
      `${debtor.first_name} ${debtor.last_name}`.toLowerCase().includes(value)
    );
    setFilteredDebtors(filtered);
  };

  const columns = [
    {
      title: "Profile Pic",
      dataIndex: "profile_pic",
      key: "profile_pic",
      render: (text) => (
        <img
          src={text}
          alt="Profile"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => navigate(`/debtors/${record.id}`)}>
          View
        </Button>
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spin />
          <p>
            <b style={{ fontWeight: "500" }}>Fetching Users...</b>
          </p>
        </div>
      ) : (
        <div>
          <Button
            type="primary"
            onClick={() => navigate("/add-debtor")}
            style={{ marginBottom: "20px" }}
          >
            Add Debtor
          </Button>
          <Input
            placeholder="Find Debtor"
            value={searchText}
            onChange={handleSearch}
            style={{ marginBottom: "20px", width: "300px" }}
          />
          <Table
            columns={columns}
            dataSource={filteredDebtors}
            scroll={{ x: true }}
            pagination={{
              pageSize: itemsPerPage,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} Debtors`,
              onShowSizeChange: (current, size) => setItemsPerPage(size),
              total: filteredDebtors.length,
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Detbors;
