import LineChart from "../components/lineChart";
import BarChart from "../components/barChart";
import RadarChart from "../components/radarChart";
import DonoughnutChart from "../components/donoughtChart";
import { Grid, Card, CardContent, Typography } from "@mui/material";

const Dashboard = () => {
  return (
    <div>
      <h2>Financial Health Summary</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <LineChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <BarChart />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <RadarChart />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <DonoughnutChart />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
