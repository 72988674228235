import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Spin, message, Button, Tag, Input } from "antd";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const { Search } = Input;

const Loans = () => {
  const [loans, setLoans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLoans = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/loans/`
        );
        setLoans(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching loans:", error);
        message.error("Failed to fetch loans. Please try again.");
        setLoading(false);
      }
    };

    fetchLoans();
  }, []);

  const currencyFormatter = (value) => `K${parseFloat(value).toFixed(2)}`;

  const getStatusTag = (status) => {
    switch (status) {
      case "Pending":
        return <Tag color="grey">Pending</Tag>;
      case "Active":
        return <Tag color="#6ec531">Active</Tag>;
      case "Closed":
        return <Tag color="#FFCE56">Closed</Tag>;
      case "Overdue":
        return <Tag color="#FF6384">Overdue</Tag>;
      default:
        return <Tag>{status}</Tag>;
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const filteredLoans = loans.filter((loan) =>
    `${loan.Debtor.first_name} ${loan.Debtor.last_name}`
      .toLowerCase()
      .includes(searchText.toLowerCase())
  );

  const columns = [
    {
      title: "Debtor",
      dataIndex: "debtor",
      key: "debtor",
      render: (text, record) =>
        `${record.Debtor.first_name} ${record.Debtor.last_name}`,
    },
    {
      title: "Loan",
      dataIndex: "loan_amount",
      key: "loan_amount",
      render: (text) => currencyFormatter(text),
    },
    {
      title: "Amount Payable",
      dataIndex: "total_amount_payable",
      key: "total_amount_payable",
      render: (text) => currencyFormatter(text),
    },
    {
      title: "Interest",
      key: "interest",
      render: (_, record) => {
        const interest =
          parseFloat(record.total_amount_payable) -
          parseFloat(record.loan_amount);
        return currencyFormatter(interest);
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text) => currencyFormatter(text),
    },

    {
      title: "Admin Fee",
      dataIndex: "admin_fee",
      key: "admin_fee",
      render: (text) => currencyFormatter(text),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      render: (text) => new Date(text).toLocaleDateString(),
    },

    {
      title: "",
      key: "action",
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => navigate(`/loans/${record.id}`)}>
            Loan Statement
          </Button>
          <Button
            type="link"
            onClick={() => navigate(`/transactions/${record.id}`)}
          >
            Transactions
          </Button>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => getStatusTag(record.status),
    },
  ];

  const totalLoanAmount = filteredLoans.reduce(
    (total, loan) => total + parseFloat(loan.loan_amount),
    0
  );
  const totalAmountPayable = filteredLoans.reduce(
    (total, loan) => total + parseFloat(loan.total_amount_payable),
    0
  );
  const totalInterest = filteredLoans.reduce(
    (total, loan) =>
      total +
      (parseFloat(loan.total_amount_payable) - parseFloat(loan.loan_amount)),
    0
  );
  const totalBalance = filteredLoans.reduce(
    (total, loan) => total + parseFloat(loan.balance),
    0
  );

  const totalAdminFee = filteredLoans.reduce(
    (total, loan) => total + parseFloat(loan.admin_fee),
    0
  );

  const exportToExcel = () => {
    let rand = Math.random().toString(36).substring(7);
    const worksheet = XLSX.utils.json_to_sheet(loans);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Loans");
    XLSX.writeFile(workbook, `${rand}loans_data.xlsx`);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Search
          placeholder="Find Debtor"
          onSearch={handleSearch}
          style={{ width: 300 }}
        />
        <Button type="primary" onClick={() => navigate("/add-loan")}>
          Add New Loan
        </Button>
      </div>
      <Button onClick={exportToExcel} style={{ marginBottom: "20px" }}>
        Export to Excel
      </Button>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spin />
          <p>
            <b style={{ fontWeight: "500" }}>Fetching Loans...</b>
          </p>
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={filteredLoans}
          rowKey="id"
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Total Loans`,
          }}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={1}>
                <b>Totals</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <b>{currencyFormatter(totalLoanAmount)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <b>{currencyFormatter(totalAmountPayable)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <b>{currencyFormatter(totalInterest)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <b>{currencyFormatter(totalBalance)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <b>{currencyFormatter(totalAdminFee)}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} colSpan={5} />
            </Table.Summary.Row>
          )}
        />
      )}
    </div>
  );
};

export default Loans;
