import React, { useState } from "react";
import { Card, Form, Input, Button, message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Ensure you have react-router-dom installed

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    const data = {
      username: values.username,
      password: values.password,
    };

    setLoading(true);
    const url = `${process.env.REACT_APP_SERVER_URL}/users/login`;

    axios
      .post(url, data)
      .then((response) => {
        setLoading(false);
        const { can_approve } = response.data;
        localStorage.setItem("can_approve", can_approve);
        navigate("/dashboard");
      })
      .catch((error) => {
        message.error("Login failed. Please check your credentials.");
        console.error("Error logging in:", error);
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={require("../assets/logo.jpeg")}
        alt="Logo"
        style={{ width: "250px" }}
      />
      <Card
        title="Banknetive Loan Management" // Optional title
        style={{ width: 400, textAlign: "center" }} // Adjust the width as needed
      >
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ width: "100%" }} // Form takes full width within Card
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password autoComplete="off" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "100%" }}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
