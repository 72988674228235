// src/pages/AddLoan.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  message,
  DatePicker,
  Card,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { storage, ref, uploadBytes, getDownloadURL } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Option } = Select;

const AddLoan = () => {
  const [form] = Form.useForm();
  const [debtors, setDebtors] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDebtors = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/debtors`
        );
        setDebtors(response.data);
      } catch (error) {
        message.error("Failed to fetch debtors. Please try again.");
      }
    };
    fetchDebtors();
  }, []);

  const handleUpload = async (file) => {
    const storageRef = ref(storage, `collateral/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const collateralDocument = await handleUpload(
        values.collateral_document.file
      );
      const existingLoanResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/loans/check?debtor_id=${values.debtor_id}`
      );

      if (existingLoanResponse.data.length > 0) {
        toast.error("Loan already exists for this debtor.");
        setLoading(false);
        return;
      }

      //interest = 15% of loan amount + loan_amount
      // Calculate interest as 15% of the loan amount only (single period, no compounding)
      const interest = parseFloat(values.loan_amount) * 0.15;

      // Total amount payable = principal + interest
      const total_amount_payable = parseFloat(values.loan_amount) + interest;

      // Calculate installment amount based on the total amount payable divided by the loan term
      const installment_amount = total_amount_payable / values.loan_term;

      await axios.post(`${process.env.REACT_APP_SERVER_URL}/loans`, {
        ...values,
        collateral_document: collateralDocument,
        collateral_value: parseFloat(values.loan_amount),
        collateral_type: "Employed",
        interest_rate: 15,
        balance: total_amount_payable,
        total_amount_payable: total_amount_payable,
        installment_amount: installment_amount,
        applied_on: values.applied_on.format("YYYY-MM-DD"),
        due_date: values.due_date.format("YYYY-MM-DD"),
      });

      message.success("Loan added successfully");
      navigate("/loans");
    } catch (error) {
      message.error("Failed to add loan. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <ToastContainer />
      <Card
        title="Add New Loan"
        bordered={false}
        style={{ maxWidth: 600, margin: "0 auto" }}
      >
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item
            name="debtor_id"
            label="Debtor"
            rules={[{ required: true, message: "Please select a debtor" }]}
          >
            <Select
              showSearch
              placeholder="Select a debtor"
              optionFilterProp="children"
              filterOption={true}
            >
              {debtors.map((debtor) => (
                <Option key={debtor.id} value={debtor.id}>
                  {debtor.first_name} {debtor.last_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="loan_amount"
            label="Loan Amount"
            rules={[
              { required: true, message: "Please enter the loan amount" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="applied_on"
            label="Applied On"
            rules={[
              { required: true, message: "Please select the application date" },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="due_date"
            label="Due Date"
            rules={[{ required: true, message: "Please select the due date" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item name="interest_rate" label="Interest Rate">
            <Input value={15} readOnly /> {/* Make the input read-only */}
          </Form.Item>

          <Form.Item
            name="admin_fee"
            label="Admin Fee"
            rules={[{ required: true, message: "Please enter the admin fee" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="collateral_type" label="Status">
            <Input readOnly={true} value="Employed" defaultValue={"Employed"} />
          </Form.Item>
          <Form.Item
            name="collateral_document"
            label="Latest Payslip"
            rules={[
              {
                required: true,
                message: "Please upload payslip",
              },
            ]}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              accept="application/pdf"
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>

          {/* New Fields */}
          <Form.Item
            name="guarantor_name"
            label="Guarantor Name"
            rules={[
              { required: true, message: "Please enter the guarantor name" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="guarantor_phone"
            label="Guarantor Phone"
            rules={[
              { required: true, message: "Please enter the guarantor phone" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="added_by"
            label="Added By"
            rules={[
              { required: true, message: "Please select who added the loan" },
            ]}
          >
            <Select>
              <Option value="Admin">Admin</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="loan_type"
            label="Loan Type"
            rules={[{ required: true, message: "Please select the loan type" }]}
          >
            <Select>
              <Option value="Personal">Personal</Option>
              <Option value="Business">Business</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="payment_frequency"
            label="Payment Frequency"
            rules={[
              { required: true, message: "Please enter the payment frequency" },
            ]}
          >
            <Select>
              <Option value="Monthly">Monthly</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="loan_term"
            label="Loan Term (months)"
            rules={[{ required: true, message: "Please enter the loan term" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="guarantor_address"
            label="Guarantor Address"
            rules={[
              { required: true, message: "Please enter the guarantor address" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="remarks" label="Remarks">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Add Loan
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AddLoan;
