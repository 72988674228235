// src/pages/Transactions.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, message } from "antd";
import * as XLSX from "xlsx";
import { useParams } from "react-router-dom";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    fetchTransactions(id); // Fetch transactions based on the loan ID
  }, [id]);

  const fetchTransactions = async (loanId) => {
    setLoading(true);
    try {
      let response;
      if (loanId) {
        response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/transactions?loan_id=${loanId}`
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/transactions`
        );
      }
      setTransactions(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      message.error("Failed to fetch transactions. Please try again.");
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Loan ID",
      dataIndex: "loan_id",
      key: "loan_id",
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => {
        switch (text) {
          case "loan_creation":
            return "Loan Creation";
          case "interest_added":
            return "Interest Added";
          case "payment":
            return "Payment";
          default:
            return text; // Display original text if no match found
        }
      },
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => `K${parseFloat(text).toFixed(2)}`,
    },
  ];

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(transactions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");
    XLSX.writeFile(workbook, "transactions_data.xlsx");
  };

  return (
    <div style={{ padding: "20px" }}>
      <Button onClick={exportToExcel} style={{ marginBottom: "20px" }}>
        Export to Excel
      </Button>
      <Table
        columns={columns}
        dataSource={transactions}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} Total Transactions`,
        }}
      />
    </div>
  );
};

export default Transactions;
