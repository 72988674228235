import React from "react";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <Header
      className="header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 20px",
        backgroundColor: "#b90000",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <img
          src={require("../assets/logo.jpeg")}
          alt="logo"
          style={{ width: "60px", marginRight: "10px" }}
        /> */}
        <h3 style={{ marginLeft: 10, color: "#fff" }}>Banknetive Loans</h3>
      </div>
      <Menu
        theme="light"
        mode="horizontal"
        style={{
          flex: 1,
          justifyContent: "flex-end",
          backgroundColor: "#b90000", // Dark red color

          borderBottom: "none", // Optional: remove any border under the Menu
        }}
      >
        <Menu.Item key="1" onClick={handleLogout} style={{ color: "white" }}>
          Logout
        </Menu.Item>
      </Menu>
    </Header>
  );
};

export default Navbar;
