import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Card, Row, Col, Button, Spin, Input, Form } from "antd";
import { set } from "react-hook-form";

const SingleDebtor = () => {
  const { id } = useParams();
  const [debtor, setDebtor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [id_front, setIdFront] = useState(null);
  const [id_back, setIdBack] = useState(null);

  useEffect(() => {
    const fetchDebtor = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/debtors/${id}`
        );
        setDebtor(response.data);
        setProfilePic(response.data.profile_pic);
        setIdFront(response.data.id_front);
        setIdBack(response.data.id_back);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching debtor:", error);
        setLoading(false);
      }
    };

    fetchDebtor();
  }, [id]);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async (values) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/debtors/${id}`,
        values
      );
      setDebtor(values);
      setEditing(false);
    } catch (error) {
      console.error("Error saving debtor:", error);
    }
  };

  return (
    <div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spin />
          <p>
            <b style={{ fontWeight: "500" }}>Loading Debtor Details...</b>
          </p>
        </div>
      ) : (
        <Card style={{ margin: "20px" }}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <img
                src={profilePic}
                alt="Profile"
                style={{ width: "50%", borderRadius: "50px" }}
              />
            </Col>
            <Col span={16}>
              <h2>
                {debtor.first_name} {debtor.last_name}
              </h2>
              <p>
                <b>Phone:</b> {debtor.phone}
              </p>
              <p>
                <b>Address:</b> {debtor.address}
              </p>
              <p>
                <b>Email:</b> {debtor.email}
              </p>
              <p>
                <b>Next of Kin:</b> {debtor.next_of_kin}
              </p>
              <p>
                <b>Next of Kin Phone:</b> {debtor.next_of_kin_phone}
              </p>
              <p>
                <b>Department:</b> {debtor.department}
              </p>
              <p>
                <b>Section:</b> {debtor.section}
              </p>
              <p>
                <b>Bank Name:</b> {debtor.bank_name}
              </p>
              <p>
                <b>Bank Account Number:</b> {debtor.bank_acc_number}
              </p>
              <p>
                <b>Employee Number:</b> {debtor.employee_number}
              </p>
              <p>
                <b>Net Pay:</b> {debtor.nett_pay}
              </p>
              {editing ? (
                <Form
                  initialValues={debtor}
                  onFinish={handleSave}
                  layout="vertical"
                >
                  <Form.Item label="First Name" name="first_name">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Last Name" name="last_name">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Phone" name="phone">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Address" name="address">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Email" name="email">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Next of Kin" name="next_of_kin">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Next of Kin Phone" name="next_of_kin_phone">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Department" name="department">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Section" name="section">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Bank Name" name="bank_name">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Bank Account Number" name="bank_acc_number">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Employee Number" name="employee_number">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Net Pay" name="nett_pay">
                    <Input />
                  </Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form>
              ) : (
                <Button type="primary" onClick={handleEdit}>
                  Edit
                </Button>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
            <Col span={12}>
              <Card title="ID Front">
                <img src={id_front} alt="ID Front" style={{ width: "100%" }} />
              </Card>
            </Col>
            <Col span={12}>
              <Card title="ID Back">
                <img src={id_back} alt="ID Back" style={{ width: "100%" }} />
              </Card>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
};

export default SingleDebtor;
